import { css, html, LitElement } from "lit"
import { atBreakpoint, brandonGrotesque } from "#js/components/styles"
import { msg, updateWhenLocaleChanges } from "@lit/localize"
import { fetchJSON } from "#js/components/http"
import { setLocale } from "#js/components/lit-i18n"
import { styleMap } from "lit/directives/style-map.js"

export class CsatSurvey extends LitElement {
  static properties = {
    participation: { type: String },
    feedback: { type: String },
    surveyUrl: { type: String },
    bookingType: { type: String },
    formTitle: { type: String },
    state: { type: String, attribute: false },
  }

  headers = {
    "Content-Type": "application/json",
    "X-CSRFToken": document.querySelector("input[name=csrfmiddlewaretoken]").value,
  }

  participationDisplay = {
    good: {
      emoji: "😊",
      text: () => msg("good"),
    },
    neutral: {
      emoji: "😐",
      text: () => msg("neutral"),
    },
    bad: {
      emoji: "🙁",
      text: () => msg("bad"),
    },
  }

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
    this.participation = ""
    this.feedback = ""
  }

  firstUpdated() {
    const urlParams = new URLSearchParams(globalThis.location.search)
    const participation = urlParams.get("participation")
    if (
      !this.participation && participation &&
      ["good", "neutral", "bad"].includes(participation)
    ) {
      this.postParticipation(participation)
      this.participation = participation
    }
  }

  static styles = css`
    @media print {
      :host {
        display: none;
      }
    }

    .csat {
      ${brandonGrotesque}

      display: flex;
      align-items: center;
      max-width: 20em;
      width: 100%;
      margin: 0 auto;
    }

    .transition {
      overflow: hidden;
      transition: max-height, opacity, padding-bottom;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }

    .emoji {
      flex: 1;
      font-size: 1.2em;
      user-select: none;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 0.8em;
      transition: background-color 0.3s, filter 0.3s;
      padding: 1em;
      border-radius: var(--border-radius);
      cursor: pointer;

      &:hover {
        filter: none !important;
      }

      ${
    atBreakpoint(
      "mobile",
      css`
        font-size: 1em;
        gap: 0.5em;
        padding: 0.75em;
      `,
    )
  }
    }

    .good:hover {
      background-color: var(--background-green);
    }

    .neutral:hover {
      background-color: var(--background-yellow);
    }

    .bad:hover {
      background-color: var(--background-red);
    }

    ${
    atBreakpoint(
      "mobile",
      css`
        .star-rating label {
          font-size: 28px;
        }
      `,
    )
  }
  `

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
      <div class="column"
           style="${
      this.getTransitionStyle(
        { condition: this.state !== "success", maxHeight: "50em" },
      )
    }"
      >
        ${this.renderForm()}
        ${this.renderFeedback()}
        <button @click="${this.postFinish}"
                   ?disabled="${this.participation === ""}"
                   style="margin-inline-start: auto;"
                   class="button button--filled">
          ${msg("done")}!
        </button>
      </div>
      ${this.renderThankYou()}
    `
  }

  renderForm() {
    return html`
      <section id="form" style="padding-block-end: 1em">
        ${this.formTitle && html`<h3>${this.formTitle}</h3>`}
        <div class="csat">
          ${this.getParticipationSelect("bad")}
          ${this.getParticipationSelect("neutral")}
          ${this.getParticipationSelect("good")}
        </div>
      </section>
    `
  }

  getParticipationSelect(participation) {
    return html`
      <div class="emoji ${participation}"
           style="${
      styleMap({
        filter: this.participation === participation ? "none" : "grayscale(100%)",
      })
    }"
           @click="${() => this.postParticipation(participation)}">
        <span style="font-size: 2em;">
          ${this.participationDisplay[participation].emoji}
        </span>
        <span style="font-size: 0.8em">
          ${this.participationDisplay[participation].text()}
        </span>
      </div>
    `
  }

  renderFeedback() {
    return html`
      <section id="feedback" class="material transition"
               style="${
      this.getTransitionStyle(
        { condition: this.participation },
      )
    }">
        <p>
          <textarea id="feedback"
                    rows="3"
                    style="resize: none;"
                    @input="${(event) => {
      this.feedback = event.target.value
    }}"
                    name="feedback">${
      this.feedback || this.nonParticipationMessage || ""
    }</textarea>
          <label for="feedback">${msg("additional comment")}</label>
        </p>
      </section>
    `
  }

  renderThankYou() {
    return html`
      <section id="success" class="transition"
               style="${
      this.getTransitionStyle({ condition: this.state === "success" })
    };">
        <h3 style="margin: 0;">
          ${msg("Thank you for your feedback. 🤩")}
        </h3>
      </section>
    `
  }

  getTransitionStyle({ condition, maxHeight = "10em", paddingBottom = "0" }) {
    return styleMap(
      {
        "max-height": condition ? maxHeight : "0",
        "padding-bottom": condition ? paddingBottom : "0",
        opacity: condition ? "1" : "0",
      },
    )
  }

  postParticipation(participation) {
    const data = {
      participation,
      booking_type: this.bookingType,
    }
    fetchJSON(this.surveyUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    }).then(() => {
      this.participation = participation
    })
  }

  postFinish() {
    const data = {
      participation: this.participation,
      is_finished: true,
      feedback_message: this.feedback,
      booking_type: this.bookingType,
    }

    fetchJSON(this.surveyUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    }).then(() => {
      this.state = "success"
    })
  }
}

globalThis.customElements.define("csat-survey", CsatSurvey)
